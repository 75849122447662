<script lang="ts" context="module">
  import { createEventDispatcher } from "svelte";
  import { scale, fly } from "svelte/transition";
  import { backOut } from "svelte/easing";
  import type { schema } from "@editor/schema";
  import { blocksConfig, isEmpty as isEmptyTest } from "./blocksConfig";
  import FallbackBlock from "./FallbackBlock.svelte";
  import { getNewsletterStores } from "../newsletterStores";
  import LayoutsButton from "./layouts/LayoutsButton.svelte";
  import StylesButton from "./styles/StylesButton.svelte";
  import ShapesButton from "./shapes/ShapesButton.svelte";
  import { isEditor, setBlockCtx } from "./utils";
  import { getSpacing } from "./spacing/spacing";
  import { isEditing as isEditingTest, isLocking } from "@editor/api";
  import Locked from "./Locked.svelte";

  const NOT_EDITABLE_BLOCK_TYPES: schema.BlockType[] = ["embed.iframe"];
  const NON_DUPLICABLE_BLOCK_TYPES: schema.BlockType[] = ["header", "logo", "signature", "embed.file", "embed.iframe"];
</script>

<script lang="ts">
  // The item we're actually showing
  export let block: schema.Block;

  // Box -> Regular widgets (with margin)
  // Clear -> Widgets outside of the newsletter (logo, signature)
  // Full -> Edge to edge widgets (like photo etc)
  export let size: "full" | "box" | "clear" = "box";
  export let calcSize: (layout: string) => "full" | "box" | "clear" = () => size;
  export let editable: boolean = !!blocksConfig[block._t].editor && isEditor();
  export let preventDelete: boolean = false;
  export let preventClicks = true;
  export let layouts: schema.BlockLayout[] = ["-"];
  export let styles: schema.BlockStyle[] = ["-"];
  export let shapes: schema.BlockShape[] = ["-"];

  export let showEmpty = false;
  // This is needed for items block, since we update the type and it sets the _lu field that we look at when cancel is clicked
  export let deleteOnCancel: ((block: schema.Block) => boolean) | undefined = undefined;

  let previewLayout: undefined | schema.BlockLayout;
  let previewStyle: undefined | schema.BlockStyle;
  let previewShape: undefined | schema.BlockShape;
  let focusField: undefined | string = undefined;

  const { content, api } = getNewsletterStores();
  // events:
  // on:deleted
  // on:click
  // on:edit_done
  // on:edit_canceled

  $: isEmpty = isEmptyTest(block);
  $: isEditing = isEditingTest(block);

  const dispatch = createEventDispatcher();

  // set the block + update it when something is changed
  setBlockCtx(block);
  $: setBlockCtx(block);

  function blockEditor(type: schema.BlockType) {
    const conf = blocksConfig[type];

    if (conf) {
      return conf.editor;
    }

    return FallbackBlock;
  }

  function nodeLookup<T>(node: HTMLElement | null, cb: (node: HTMLElement | null) => T | null): T | null {
    while (node) {
      const r = cb(node);
      if (r) {
        return r;
      }
      node = node.parentElement;
    }
    return null;
  }

  function startEdit(e: MouseEvent) {
    maybePreventLinks(e);

    if (!editable || isEditing || NOT_EDITABLE_BLOCK_TYPES.includes(block._t)) {
      return;
    }

    // set focus field
    focusField = (e.target as HTMLElement).closest("[data-field-key]")?.getAttribute("data-field-key") || undefined;

    setEditingState("editing");
  }

  function maybePreventLinks(e: MouseEvent) {
    // not preventing, exit
    if (!preventClicks) {
      return;
    }

    const a = (e.target as HTMLElement).closest("a");

    // cancel a link if we have an href
    if (a && a.getAttribute("href")) {
      e.stopPropagation();
      e.preventDefault();
    }
  }

  function setEditingState(newState: "editing" | "empty" | undefined) {
    if (newState === "editing") {
      _unlock = api.lock(block._id, "edit");
    } else {
      _unlock();
    }
  }

  function onEditingDone(e: { detail: schema.Block }) {
    // clear focus field
    focusField = undefined;
    // Update when editing is done

    content.updateById(e.detail._id, (b) => {
      // override all the fields
      for (let k in e.detail) {
        // @ts-ignore
        b[k] = e.detail[k];
      }
    });

    dispatch("edit_done");
  }

  let _unlock: () => void = () => {
    // default is unlock if we're locking
    if (isLocking(block)) {
      api.clearLock(block._id);
    }
  };

  function onEditingCanceled() {
    _unlock(); // unlock

    // clear focus field
    focusField = undefined;
    dispatch("edit_canceled");

    if (block._t !== "signature" && block._t !== "header" && block._t !== "logo") {
      // if we never saved this block, and we cancel, remove it
      if (!block._lu) {
        dispatch("delete");
        return;
      }

      if (deleteOnCancel && deleteOnCancel(block)) {
        dispatch("delete");
        return;
      }
    }

    // // if we're canceling an empty item, let's delete it
    // if (originalState === "empty") {
    //   // dispatch("delete");
    //   // deleting = false;
    //   setEditingState(originalState);
    // } else {
    //   setEditingState(undefined);
    // }
  }

  const space = getSpacing();

  function layoutChanged(e: { detail: { newLayout: schema.BlockLayout } }) {
    content.updateById(block._id, (b) => {
      b._l = e.detail.newLayout;
    });
  }

  function styleChanged(e: { detail: { newStyle: schema.BlockStyle } }) {
    content.updateById(block._id, (b) => {
      b._style = e.detail.newStyle;
    });
  }

  function shapeChanged(e: { detail: { newShape: schema.BlockShape } }) {
    content.updateById(block._id, (b) => {
      b._shape = e.detail.newShape;
    });
  }

  let deleting = false;
  function onDelete() {
    if (!deleting) {
      deleting = true;
      return;
    }
    dispatch("delete");
    deleting = false;
  }

  function onDuplicate() {
    dispatch("duplicate");
  }

  function doLoggedOut() {
    api.reLogin();
  }

  function getTOCText() {
    switch (block._t) {
      case "text.title":
        return (block as schema.TitleBlock).title;
      default:
        return undefined;
    }
  }

  $: size = calcSize(previewLayout || block._l);
</script>

{#if isEditing && editable && block._t !== "header"}
  <div class:m-2={size !== "clear"} class="editing" in:scale={{ duration: 200, start: 0.9, easing: backOut }}>
    <svelte:component
      this={blockEditor(block._t)}
      {block}
      on:cancel={onEditingCanceled}
      on:done={onEditingDone}
      {focusField}
      on:logged_out={doLoggedOut} />
  </div>
{:else}
  <div class="block-wrapper relative">
    {#if isEditor()}
      <Locked item={block} id={block._id} />
    {/if}

    <!-- Header editing hack -->
    {#if isEditing && editable && block._t === "header"}
      <div class="editing absolute inset-0 z-10 flex items-start" in:scale={{ duration: 200, start: 0.9, easing: backOut }}>
        <div class="mx-2 mt-1 w-full overflow-hidden rounded">
          <svelte:component
            this={blockEditor(block._t)}
            {block}
            on:cancel={onEditingCanceled}
            on:done={onEditingDone}
            {focusField}
            on:logged_out={doLoggedOut} />
        </div>
      </div>
    {/if}
    <div
      class="block-box group relative"
      class:overflow-hidden={block._t === "header"}
      class:rounded={size === "clear" && block._t !== "header"}
      class:min-height={isEditor() && block._t === "image.single"}
      class:block-wrap={isEditor()}
      class:pointer-events-none={isEditing && (block._t === "header" || block._t === "signature")}
      class:px-5={size == "box" && !isEmpty}
      class:p-3={size == "box" && isEmpty}
      class:empty={isEmpty}
      class:m-2={isEmpty && size == "box"}
      class:rounded-lg={isEmpty && size == "box"}
      class:border-2={isEmpty && size == "box"}
      class:border-dashed={isEmpty && size == "box"}
      class:border-gray-200={isEmpty && size == "box"}
      style="--before: {size === 'box' ? $space?.before || 0 : 0}px;--after:{size === 'box' ? $space?.after || 0 : 0}px"
      on:click={startEdit}
      on:mousedown={maybePreventLinks}
      class:cursor-pointer={editable && (block._t === "header" || block._t === "signature")}>
      <slot name="background" />

      <!-- "full" empty box -->

      {#if size === "full" && isEmpty}
        <div class="full-outline absolute inset-2 rounded-lg border-2 border-dashed border-gray-200" />
      {/if}

      <div
        class="block-content relative w-full rounded"
        data-block-type={block._t}
        data-block-id={`b${block._id}`}
        data-block-toc={block._toc ? true : undefined}
        data-toc-text={getTOCText()}
        class:empty-placeholder={isEmpty}
        in:scale|local={{ duration: 200, start: 0.9, easing: backOut }}>
        <span class="anchor invisible relative block" id={`b${block._id}`} class:-top-14={true} />
        <!-- content -->
        {#if !isEmpty || showEmpty}
          <slot
            {block}
            {size}
            layout={previewLayout || block._l}
            style={previewStyle || block._style}
            shape={previewShape || block._shape}
            {isEmpty}
            {isEditing}
            logged_out={doLoggedOut}>
            <div class="my-5 rounded bg-red-600 p-3 text-center text-white">No content is defined for {block._t}</div>
          </slot>
        {/if}
      </div>

      {#if isEditor()}
        {#if !isEditing}
          <div
            class="editing-tools block-no-move absolute flex items-center"
            class:top-2={isEmpty && size == "box" && block._t !== "misc.separator"}
            class:right-2={isEmpty && size == "box"}
            class:top-3={(!isEmpty || size !== "box") && block._t !== "misc.separator"}
            class:right-3={!isEmpty || size !== "box"}
            class:top-2.5={block._t === "misc.separator"}>
            {#if !NON_DUPLICABLE_BLOCK_TYPES.includes(block._t)}
              <div
                class="z-90 copy-item clickable scale-in relative mr-1 flex h-6 cursor-pointer items-center justify-center self-center rounded bg-brand-gray-80 px-2 text-sm text-white transition-colors hover:bg-green-700 hover:text-white"
                on:click|preventDefault|stopPropagation={onDuplicate}>
                Duplicate
              </div>
            {/if}
            {#if block._t !== "header" && shapes && shapes.length > 1}
              <ShapesButton
                {isEditing}
                {shapes}
                {block}
                bind:previewShape
                on:shape_changed={shapeChanged}
                let:preview
                on:open={() => (_unlock = api.lock(block._id, "preemptive"))}
                on:cancel={_unlock}>
                <!-- support for an extra shape editing options -->
                <slot name="layout_extras" {preview} />
              </ShapesButton>
            {/if}
            {#if block._t !== "header" && styles && styles.length > 1}
              <StylesButton
                {isEditing}
                {styles}
                {block}
                bind:previewStyle
                on:style_changed={styleChanged}
                let:preview
                on:open={() => (_unlock = api.lock(block._id, "preemptive"))}
                on:cancel={_unlock} />
            {/if}
            {#if block._t !== "header" && layouts && layouts.length > 1}
              <LayoutsButton
                {isEditing}
                {layouts}
                {block}
                bind:previewLayout
                on:layout_changed={layoutChanged}
                let:preview
                on:open={() => (_unlock = api.lock(block._id, "preemptive"))}
                on:cancel={_unlock}>
                <!-- support for an extra layout editing options -->
                <slot name="layout_extras" {preview} />
              </LayoutsButton>
            {/if}

            <!-- Edit button -->
            {#if editable && !NOT_EDITABLE_BLOCK_TYPES.includes(block._t)}
              <div
                class="z-90 edit-item clickable scale-in relative flex h-6 cursor-pointer items-center justify-center self-center rounded bg-brand-gray-80 px-2 text-sm text-white transition-colors hover:bg-blue-700 hover:text-white"
                on:click={startEdit}>
                Edit
              </div>
            {/if}
            {#if !preventDelete}
              <button
                on:click|preventDefault|stopPropagation={onDelete}
                on:mouseleave={() => (deleting = false)}
                class="block-no-move delete-item clickable scale-in ml-1 flex h-6 cursor-pointer items-center justify-center self-center rounded bg-brand-gray-80 px-0.5 text-white outline-none transition-colors hover:bg-red-800 hover:text-orange-100 focus:outline-none">
                <i class="material-icons pointer-events-none block text-18">delete</i>
                {#if deleting}<span in:fly={{ duration: 150, x: 20 }} class="pointer-events-none pl-0.5 pr-1 text-sm">Delete?</span>{/if}
              </button>
            {/if}
          </div>
        {/if}
      {/if}
    </div>
  </div>
{/if}

<style>
  .block-wrap .editing-tools {
    opacity: 0;
    pointer-events: none;
  }

  .block-wrap:hover .editing-tools {
    pointer-events: all;
    /* transition: all 0.1s ease; */
    opacity: 1;
    /* transition-delay: 0.05s; */
  }

  .block-box.min-height:hover .block-content {
    min-height: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .block-content.empty-placeholder {
    border-color: transparent !important;
    opacity: 0.5;
    pointer-events: none;
  }
  .block-wrap.empty {
    background-color: #eee;
  }

  .block-wrap:hover {
    background: #88ecdf5b;
  }

  .block-wrap.empty:hover,
  .block-wrap.empty:hover .full-outline {
    border-color: #51e0cd;
    background: #dcf4f1;
  }

  .block-wrap :global(.block-overlay) {
    display: none;
  }

  .block-wrap:hover :global(.block-overlay) {
    display: block;
  }

  .block-wrap :global(a[href]) {
    cursor: move;
  }

  .block-box {
    padding-top: var(--before);
    padding-bottom: var(--after);
    break-inside: auto;
  }
</style>
