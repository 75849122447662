<script lang="ts" context="module">
  import type { schema } from "@editor/schema";
  import cloneDeep from "lodash.clonedeep";
  import { getNewsletterStores } from "../newsletterStores";
  import { blockAutosave } from "./form/autosave";
  import Form from "./form/Form.svelte";

  export function toDraft<T extends schema.Block>(input: T): T {
    return blockAutosave.load(input) ?? cloneDeep(input);
  }
</script>

<script lang="ts">
  export let draft: schema.Block;
  export let saveButtonText: string = "Save";
  export let bgClass: string = "";
  export let focusKey: string | undefined = undefined;

  // an optional validation function on the draft
  export let validate: (draft: any) => string[] | undefined | void = () => [];

  // Override submit action to implement an async submit system (like submitting data to and endpoint etc)
  export let submitAction: (data: any) => Promise<any> = async (d) => d;

  export let beforeSubmit: (data: any) => Promise<any> = async (d) => d;

  // Override for special error handling
  export let errorAction: (data: any, e: any) => void = () => {};

  const { api } = getNewsletterStores();
</script>

<div class="m-2 my-4 border border-gray-200 rounded shadow {bgClass}">
  <Form
    data={draft}
    on:done
    on:cancel
    let:canSubmit
    let:errors
    let:isDirty
    let:isSubmitting
    let:waiting
    autosave={blockAutosave}
    {validate}
    {submitAction}
    {beforeSubmit}
    {errorAction}>
    <slot {canSubmit} {errors} {isDirty} {isSubmitting} {waiting} logged_out={api.reLogin} />
  </Form>
</div>
